import React from 'react';
import {
  BrowserRouter as Router,
  Route,
} from 'react-router-dom';
import ReactDOM from 'react-dom';
import './resources/css/styles.css';
import Header from './Components/Header';
import Footer from './Components/Footer';
import ScrollToTop from './Components/ScrollToTop';
import Home from './Pages/Home';
import reportWebVitals from './reportWebVitals';

ReactDOM.render(
  <React.StrictMode>
    <Router>
      <ScrollToTop />
      <Header />
        <Route path='/' component={Home} exact/>
      <Footer />
    </Router>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
