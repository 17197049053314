import React from 'react';
// import { Link } from 'react-router-dom';
// import IGlogo from '../resources/images/socials/instagram.svg';

function Footer() {
  return (
    <>
        <footer class="swatch-1">
            <div class="foot-container">
                <h2>Crackle Today</h2>
                <br/><br/>
                <ul class="foot-location">
                    <li>
                        <h3>Roasted In</h3>
                    </li>
                    <br/>
                    <li>Kelowna, BC</li>
                </ul>
                <ul class="foot-contact">
                    <li>
                        <h3>Reach us</h3>
                    </li>
                    <li>
                        <a class="foot-email a-alt foot-btn" href="mailto:info@cracklingcoffee.com?subject=Getting%20in%20Touch%20with%20Crackling%20Coffee">email</a>
                    </li>
                </ul>
                <h6 class="copyright">Copyright &copy; 2021 Crackling Coffee Inc. </h6>
            </div>
        </footer>
    </>
  );
}

export default Footer;
