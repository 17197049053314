import React from 'react';
import { Link } from 'react-router-dom';
import logo from "../resources/images/brand/CracklingCoffee-Logo(Yellow)-01.svg";

function Header() {
  return (
    <>
      <header className="main-nav">
          <div className="main-nav-bar">
              <div className="header-img">
                  <Link to="/">
                     <img src={logo} class="logo nav-item" alt="Ramen Creative logo"/>
                  </Link>
              </div>
              <nav className="">
                  <ul className="header-nav">
                      {/* <li><Link to="/about" class="nav-item first">About</Link></li>
                      <li><Link to="/contact" class="nav-item">Contact</Link></li>
                      <li className=""><Link to="/services/BrandAudit" class="nav-item last">Buy Beans</Link></li> */}
                  </ul>
              </nav>
          </div>
      </header>
    </>
  );
}

export default Header;
