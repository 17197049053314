import React from 'react';

function Home() {
  return (
    <>
      <div className="box-set">
        <main>
          <h2>
            Crackling Coffee
          </h2>
          <br/><br/>
          <h3>
            We're busy perfecting our roast.<br/><br/>
            Leave your email to be notified when we drop our first batch!
          </h3>
          <form id="khaosForm" name="email-form" data-name="Email form" method="POST" className="swatch-2 main-section design-guide-section odd">
            
            <input type="hidden" name="source" value="Crackling Coffee"/>
            <section className="Name">
                <input type="text" placeholder="Name" name="name" data-name="Name" id="name"/>
            </section>
            <section className="Email">
                <input type="email" placeholder="Email" name="email" data-name="Email" id="email" required=""/>
            </section>
            <section className="submit-btn" id="form-submit">
                <input type="submit" value="Send" className="btn a-alt2" data-wait="Please wait..."/>
            </section>
            <aside className="ramen-form-fail ramen-form" id="ramen-form-fail">
                <p>
                    Oops! Something went wrong while submitting the form.<br/><br/>
                    Please double-check that all of the entry fields are right.
                </p>
            </aside>
            <aside class="ramen-form-done ramen-form swatch-1 main-section design-guide-section" id="ramen-form-done">
            <p>
                <br/>
                Thank you, your submission has been received!<br/><br/>
                Someone from our team will get back to you within 24 hours.
            </p>
        </aside>
          </form>
        </main>
      </div>
    </>
  );
}

export default Home;
